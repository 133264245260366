export const formatCurrency = function (
    val: number,
    fractionDigits = 2,
    currency: string = 'USD') {

    return Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
        currencySign: "accounting",
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
    }).format(val);
};