import * as React from 'react';
import {useEffect} from 'react';
import {Link} from "react-router-dom";
import {ClientRoutes} from "../clientRoutes";
import Logo from "../common/Logo";
import {PageTitles} from '../pageTitles';

export default function TermsOfUse() {

    useEffect(() => {
        document.title = PageTitles.publicTerms;
    }, []);

    return (
        <div className="container public mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <Logo/>
                    <h1>Terms of Use</h1>
                    <h2>Introduction</h2>
                    <ul>
                        <li>
                            Welcome to CFOdigital.ai, a platform provided by FinRatio LLC that allows businesses to
                            generate and access financial performance reports. By using our services, you agree to
                            comply with these Terms of Use. Please read them carefully.
                        </li>
                    </ul>
                    <h2>Eligibility</h2>
                    <ul>
                        <li>
                            To use CFOdigital.ai, you must be at least 18 years old and legally authorized to represent
                            the company for which you provide financial data. By creating an account, you confirm that
                            you meet these eligibility requirements.
                        </li>
                    </ul>
                    <h2>User Obligations</h2>
                    <ol>
                        <li>
                            <strong>Account Creation:</strong> When creating an account, you agree to provide accurate
                            and complete information. You are responsible for maintaining the confidentiality of your
                            account credentials and for all activities that occur under your account.
                        </li>
                        <li>
                            <strong>Compliance:</strong> You agree to comply with all applicable laws, including those
                            related to financial reporting and data privacy.
                        </li>
                        <li>
                            <strong>User Conduct:</strong> You must not use CFOdigital.ai to engage in any unlawful,
                            offensive, or harmful activities. This includes submitting false or misleading financial
                            data, infringing on intellectual property rights, or attempting to disrupt our services
                        </li>
                    </ol>
                    <h2>Financial Data and Reports</h2>
                    <ol>
                        <li>
                            <strong>Data Collection:</strong> By using CFOdigital.ai, you agree to provide accurate and
                            current financial statement data for the purpose of generating financial performance
                            analysis reports.
                        </li>
                        <li>
                            <strong>Report Generation:</strong> The reports generated by CFOdigital.ai are based on the
                            financial data you submit. FinRatio LLC does not guarantee the accuracy of the analysis if
                            the submitted data is incomplete or inaccurate.
                        </li>
                        <li>
                            <strong>Data Storage:</strong> CFOdigital.ai will store the financial data you submit and
                            the reports generated on your behalf for your future access. You are solely responsible for
                            the accuracy of the data provided.
                        </li>
                    </ol>
                    <h2>Content Ownership & Intellectual Property</h2>
                    <ol>
                        <li>
                            <strong>User Data:</strong> You retain ownership of the financial data you submit to the
                            platform. However, by submitting data, you grant FinRatio LLC a non-exclusive, royalty-free,
                            worldwide license to use, store, and process the data solely for the purpose of providing
                            services to you.
                        </li>
                        <li>
                            <strong>Platform Content:</strong> All content provided by CFOdigital.ai, including reports,
                            templates, and analytics, is the property of FinRatio LLC and is protected by copyright,
                            trademark, and other intellectual property laws.
                        </li>
                    </ol>
                    <h2>Limitations of Liability</h2>
                    <ul>
                        <li>
                            CFOdigital.ai and FinRatio LLC are not liable for any direct, indirect, incidental, or
                            consequential damages resulting from the use of our platform or services, including
                            financial or business losses incurred from reliance on the analysis reports. All services
                            are provided &quot;as is&quot; without warranties of any kind.
                        </li>
                    </ul>
                    <h2>Termination of Use</h2>
                    <ul>
                        <li>
                            FinRatio LLC reserves the right to terminate or suspend your account and access to
                            CFOdigital.ai at any time, without notice, if we believe you have violated these Terms of
                            Use or engaged in illegal or harmful conduct. You may terminate your account at any time by
                            contacting customer support.
                        </li>
                    </ul>
                    <h2>Dispute Resolution</h2>
                    <ul>
                        <li>
                            Any disputes arising out of these Terms of Use will be governed by the laws of the State of
                            Delaware, USA, without regard to its conflict of laws provisions. Disputes will be resolved
                            through binding arbitration in Delaware, unless prohibited by law.
                        </li>
                    </ul>
                    <h2>Modifications to Terms</h2>
                    <ul>
                        <li>
                            FinRatio LLC reserves the right to modify these Terms of Use at any time. We will notify you
                            of any significant changes, and your continued use of CFOdigital.ai after such changes
                            constitutes acceptance of the new terms.
                        </li>
                    </ul>
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li>
                            Please review our <Link to={ClientRoutes.publicPrivacyPolicy}>Privacy Policy </Link> for
                            information on how FinRatio LLC collects, uses, and protects your personal and financial
                            data.
                        </li>
                    </ul>
                    <h2>Contact Information</h2>
                    <ul>
                        <li>
                            If you have any questions about these Terms of Use, please contact us at
                            support@finratio.ai.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}