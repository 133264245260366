import * as React from "react";
import {FeatureStatus} from "../../featureStatus";
import NoCompaniesExist from "../../common/NoCompaniesExist";
import NoReportsExist from "../../common/NoReportsExist";
import PageError from "../../common/PageError";
import Status from "../../common/Status";
import useCompanyReport from "../../useCompanyReport";
import ReportContent from "./ReportContent";
import ReportHeader from "./ReportHeader";

export function ReportList({companyId}: any) {

    const {status, message, company, reportList} = useCompanyReport(companyId);

    let template;

    if (status === FeatureStatus.IsLoading) {
        template = (<Status message="Loading reports"/>);
    } else if (status === FeatureStatus.HasError) {
        template = (<PageError message={message}/>);
    } else if (status === FeatureStatus.IsMissingCompany) {
        template = (<NoCompaniesExist/>);
    } else if (status === FeatureStatus.IsMissingReport) {
        template = (<NoReportsExist/>);
    } else {
        template = (<ReportContent company={company} reportList={reportList}/>);
    }

    return (
        <>
            <ReportHeader pretitle="Financial Reports" company={company} isNew={false}/>
            {template}
        </>
    );
}