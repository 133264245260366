import {Kpi} from "./kpi";
import {KpiCategoryKey} from "./kpiCategoryKey";
import {sortKpis} from "./metricUtils";

export class KpiList {
    kpis: Kpi[] = [];

    fromJson(json: any) {
        const results = json;
        let kpiList: Kpi[] = [];

        results.forEach(function (item: any) {
            const kpi = new Kpi();
            kpi.fromJson(item);
            kpiList.push(kpi);
        });

        // Sort according to UI requirements.
        kpiList = sortKpis(kpiList);

        this.kpis = kpiList;
    }

    getCategoryName(categoryKey: KpiCategoryKey) {
        let name = '';

        this.kpis.forEach(function (kpi: Kpi) {
            if (kpi.kpiCategoryKey === categoryKey) {
                name = kpi.kpiCategoryName;
                return;
            }
        });

        return name;
    }

    getKpisByCategory(categoryKey: KpiCategoryKey) {
        const results: Kpi[] = [];

        this.kpis.forEach(function (kpi: Kpi) {
            if (kpi.kpiCategoryKey === categoryKey) {
                results.push(kpi);
            }
        });

        return results;
    }
}