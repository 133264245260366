import * as React from 'react';

export default function PageError({message}: any) {

    return (
        <div className="error">
            <h3 className="text-danger">An unexpected error occurred.</h3>
            <small className="text-danger">{message}</small>
        </div>
    );
}