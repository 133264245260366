import {createContext} from 'react';

export interface UserCompany {
    getCompanies: () => any[];
    setCompanies: (companies: any[]) => void;

    getCurrentCompanyId: () => string;
    setCurrentCompanyId: (companyId: string) => void;
}

export const defaultContext: UserCompany = {
    getCompanies: () => [],

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCompanies: (companies) => {
    },

    getCurrentCompanyId: () => '',

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCurrentCompanyId: (companyId) => {
    }
};

const CompanyContext = createContext(defaultContext);

export {CompanyContext};

