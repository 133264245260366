import {isEmpty} from "../../utils/pageUtils";
import {Industry} from "./industry";

export class IndustryList {
    industries: Industry[] = [];
    sectors: any = {};
    sectorNames: any = {};

    fromJson(json: any) {
        const results = json;
        const industryList: Industry[] = [];

        results.forEach(function (item: any) {
            const industry = new Industry();
            industry.fromJson(item);
            industryList.push(industry);
        });

        this.industries = industryList;

        // Get all subsectors by their parent sector.
        const sectors: any = {};
        const sectorNames: any = {};

        industryList.forEach(function (item: Industry) {
            const sectorKey = item.sectorKey;
            const subsectorKey = item.subsectorKey;

            if (!(sectorKey in sectorNames)) {
                sectorNames[sectorKey] = item.sectorName;
            }

            if (!(subsectorKey in sectorNames)) {
                sectorNames[subsectorKey] = item.subsectorName;
            }

            if (!(sectorKey in sectors)) {
                sectors[sectorKey] = [];
            }

            sectors[sectorKey].push(item.subsectorKey);
        });

        this.sectors = sectors;
        this.sectorNames = sectorNames;
    }

    /** Returns a list of all sector keys. **/
    getSectorKeys() {
        return Object.keys(this.sectors);
    }

    /** Returns the sector or subsector name by its key. **/
    getSectorNameByKey(key: string) {
        if (!isEmpty(this.sectorNames)) {
            return this.sectorNames[key];
        }

        return '';
    }

    getSubsectorKeys(sectorKey: string) {
        return this.sectors[sectorKey];
    }
}