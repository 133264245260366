import {useEffect, useState} from "react";
import {ApiRoutes} from "../../apiRoutes";
import {useAuth} from "../../AuthProvider";
import {useCompany} from "../../CompanyProvider";
import {postData} from "../../utils/postData";
import {getHeaders} from "../../utils/requestHeaders";
import {FeatureStatus} from "../featureStatus";
import {PostStatus} from "../../utils/postStatus";

// TODO: This is being re-rendered because it's using auth context.
// TODO: Setting auth and company provider is causing entire function to reload.
// TODO: Use memo to skip re-rendering.
// TODO: Something is causing this to exit and remount.
export default function useUserSignIn() {
    const {signIn, setUser} = useAuth();
    const {setCompanies, setCurrentCompanyId} = useCompany();

    const [status, setStatus] = useState(FeatureStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (data: any) => {

            if (status !== FeatureStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                const message = results.message;

                if (results.postStatus === PostStatus.UserEmailUnverified) {
                    setStatus(FeatureStatus.UserEmailUnverified);
                    setMessage(message);
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(FeatureStatus.RequiresAuth);
                    return true;
                }


                const errors = results.errors;

                if (results.hasError) {
                    setStatus(FeatureStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            let headers = getHeaders('');
            const signInResults = await postData(ApiRoutes.userSignIn, data, headers, signal);

            if (doExitEarly(signInResults)) {
                return;
            }

            const tokenResults = signInResults.results;

            // @ts-expect-error Returns dict for token.
            const accessToken = tokenResults.access_token;

            // @ts-expect-error Returns dict for token.
            const expires = tokenResults.expires_in;

            //
            // Get user info + entitlements.
            //

            // Get headers with new token.
            headers = getHeaders(accessToken);

            const userGetResult = await postData(ApiRoutes.userGet, {}, headers, signal);

            if (doExitEarly(userGetResult)) {
                return;
            }

            const userGetResults = userGetResult.results;

            //
            // Get user company list.
            //

            const companyListResult = await postData(ApiRoutes.companiesList, {}, headers, signal);

            if (doExitEarly(companyListResult)) {
                return;
            }

            const companyListResults = companyListResult.results;

            const currentCompanyId =
                companyListResults.length > 0
                    ? companyListResults[0].company_id
                    : '';

            // Finish all calls before setting any state or providers.
            setStatus(FeatureStatus.HasSubmitted);
            signIn(accessToken, parseInt(expires));
            setUser(userGetResults[0]);
            setCompanies(companyListResults);
            setCurrentCompanyId(currentCompanyId);
        };

        postForm(formData).then();

        return () => {
            controller.abort();
        };

    }, [status, formData, signIn, setUser, setCompanies, setCurrentCompanyId]);

    const signInUser = (data: any) => {
        setStatus(FeatureStatus.IsSubmitting);
        setMessage('');
        setFormData(data);
    };

    return {
        status, message, validationErrors, signInUser
    };
}