import {round} from "../../utils/formatDecimal";

const CORE_KPI_KEYS: string[] = ['net_margin', 'ebit', 'roa', 'roe', 'cash_ratio', 'ocg'];

export function getBalanceSheetInfo(
    companyBenchmarks: any[],
    plcBenchmarks: any[]) {

    const companyAssetsTemp: any = [];
    const companyLiabilitiesTemp: any = [];

    companyBenchmarks.forEach(function (item: any) {
        const categoryKey = item.account_category_key;

        if (categoryKey === 'assets') {
            companyAssetsTemp.push(item);
        } else if (categoryKey === 'liabilities' || categoryKey === 'equity') {
            companyLiabilitiesTemp.push(item);
        }
    });

    const indAssetsTemp: any = [];
    const indLiabilitiesTemp: any = [];

    plcBenchmarks.forEach(function (item: any) {
        const categoryKey = item.account_category_key;

        if (categoryKey === 'assets') {
            indAssetsTemp.push(item);
        } else if (categoryKey === 'liabilities' || categoryKey === 'equity') {
            indLiabilitiesTemp.push(item);
        }
    });

    // Reorder as per Ricardo.
    const ASSETS_ORDER = [
        'cash_pct',
        'accounts_receivable_pct',
        'inventory_pct',
        'other_current_assets_pct',
        'ppe_pct',
        'intangibles_pct',
        'other_noncurrent_assets_pct'
    ];

    const companyAssets: any[] = [];
    const indAssets: any[] = [];

    ASSETS_ORDER.forEach(function (benchmark_key: string) {
        companyAssetsTemp.forEach(function (item: any) {
            const bmk = item.benchmark_key;

            if (bmk === benchmark_key) {
                companyAssets.push(item);
                return;
            }
        });

        indAssetsTemp.forEach(function (item: any) {
            const bmk = item.benchmark_key;

            if (bmk === benchmark_key) {
                indAssets.push(item);
                return;
            }
        });
    });

    const LIABILITIES_ORDER = [
        'accounts_payable_pct',
        'notes_interest_debt_pct',
        'other_accrued_liabilities_pct',
        'other_current_liabilities_pct',
        'noncurrent_liabilities_pct',
        'equity_pct'
    ];

    const companyLiabilities: any[] = [];
    const indLiabilities: any[] = [];

    LIABILITIES_ORDER.forEach(function (benchmark_key: string) {
        companyLiabilitiesTemp.forEach(function (item: any) {
            const bmk = item.benchmark_key;

            if (bmk === benchmark_key) {
                companyLiabilities.push(item);
                return;
            }
        });

        indLiabilitiesTemp.forEach(function (item: any) {
            const bmk = item.benchmark_key;

            if (bmk === benchmark_key) {
                indLiabilities.push(item);
                return;
            }
        });
    });

    return [companyAssets, companyLiabilities, indAssets, indLiabilities];
}

export function getCcpInfo(
    companyPrognosesCcp: any[]) {

    const results: any = {};

    companyPrognosesCcp.forEach(function (item: any) {
        const key = item.kpi_key;

        results[key] = {
            'kpi_key': item.kpi_key,
            'kpi_name': item.kpi_name,
            'kpi_value': item.kpi_prognosis_value
        };
    });

    return results;
}

export function getKpisVsIndustryInfo(
    companyKpis: any[],
    plcKpiAggs: any[]) {
    // Combine both lists by kpi key.
    const kpisByKeyTemp: any = {};

    companyKpis.forEach(function (item: any) {
        const key = item.kpi_key;

        kpisByKeyTemp[key] = {
            'kpi_key': item.kpi_key,
            'kpi_name': item.kpi_name,
            'kpi_value': item.kpi_value,
            'kpi_category_key': item.kpi_category_key,
            'kpi_category_name': item.kpi_category_name
        };
    });

    // Update kpis with plc agg info.
    plcKpiAggs.forEach(function (item: any) {
        const existingItem = kpisByKeyTemp[item.kpi_key];
        const aggKey = 'agg_' + item.agg_key.replace('%', '') + '_value';
        const source: any = {};
        source[aggKey] = item.agg_value;
        kpisByKeyTemp[item.kpi_key] = Object.assign({}, existingItem, source);
    });

    // Reorder as per Ricardo.
    const KPI_ORDER = [
        'current_ratio',
        'quick_ratio',
        'cash_ratio',

        'dso',
        'adi',
        'adp',
        'cash_cycle',

        'ar_turnover',
        'inventory_turnover',
        'ap_turnover',
        'wc_turnover',
        'total_asset_turnover',
        'fixed_asset_turnover',
        'tangible_asset_turnover',
        'ppe_turnover',

        'roa',
        'roe',

        'net_margin',
        'ebit',
        'basic_earning_power',
        'roce',

        'debt_ratio',
        'long_term_debt_ratio',
        'de_ratio',

        'ncg',
        'ocg',
        'clcc',
        'ocs',
        'qpt',
        'qoffur',

        'lyca',
        'iaicoc',
        'roa2bond'
    ];

    const kpisByKey: any = {};

    // TODO: Replace with code in metricUtils.
    KPI_ORDER.forEach(function (kpi_key: string) {
        Object.keys(kpisByKeyTemp).forEach(temp_key => {
            if (kpi_key === temp_key) {
                kpisByKey[temp_key] = kpisByKeyTemp[temp_key];
            }
        });
    });

    // Divide by KPI category key.
    const companyKpisByCategory: any = {
        'liquidity': [],
        'days': [],
        'turnover': [],
        'return': [],
        'profitability': [],
        'leverage': [],
        'cashflow': [],
        'lyca': [],
        'iaicoc': [],
        'roa2bond': []
    };

    Object.keys(kpisByKey).forEach(key => {
        const item = kpisByKey[key];

        companyKpisByCategory[item.kpi_category_key].push({
            'kpi_key': item.kpi_key,
            'kpi_name': item.kpi_name,
            'kpi_value': round(item.kpi_value, 3),
            'kpi_category_name': item.kpi_category_name,
            'agg_25_value': round(item.agg_25_value, 3),
            'agg_50_value': round(item.agg_50_value, 3),
            'agg_75_value': round(item.agg_75_value, 3)
        });
    });

    return companyKpisByCategory;
}

export function getPrognosisVsIndustryInfo(
    companyKpis: any[],
    companyPrognoses: any[],
    plcKpiAggs: any[],
    industryPrognoses: any[]) {

    const prognosis: any = {
        'cash_ratio': [],
        'ebit': [],
        'net_margin': [],
        'ocg': [],
        'roa': [],
        'roe': []
    };

    companyKpis.forEach(function (item: any) {
        if (CORE_KPI_KEYS.indexOf(item.kpi_key) === -1) {
            return;
        }

        prognosis[item.kpi_key].push({
            'name': 'Company\nCurrent',
            'value': round(item.kpi_value, 3)
        });
    });

    companyPrognoses.forEach(function (item: any) {
        prognosis[item.kpi_key].push({
            'name': 'Company\nPrognosis',
            'value': round(item.kpi_prognosis_value, 3)
        });
    });

    plcKpiAggs.forEach(function (item: any) {
        if (CORE_KPI_KEYS.indexOf(item.kpi_key) === -1) {
            return;
        }

        // The agg_keys are returned in order: 25, 50, 75.
        prognosis[item.kpi_key].push({
            'name': 'Industry\n' + item.agg_key,
            'value': round(item.agg_value, 3)
        });
    });

    industryPrognoses.forEach(function (item: any) {
        if (CORE_KPI_KEYS.indexOf(item.kpi_key) === -1) {
            return;
        }

        prognosis[item.kpi_key].push({
            'name': 'Ind Avg\nPrognosis',
            'value': round(item.kpi_prognosis_value, 3)
        });
    });

    return prognosis;
}

export function getSeddaInfo(
    companyKpis: any[],
    companyPrognoses: any[],
    plcKpiAggs: any[],
    plcPrognoses: any[]) {

    const seddaResult: any = {};

    // Get company current values.
    companyKpis.forEach(function (item: any) {
        const key = item.kpi_key;

        if (CORE_KPI_KEYS.indexOf(key) > -1) {
            const w: any = {
                'current_value': item.kpi_value,
                'predicted_value': 0
            };

            const x: any[] = [];
            x.push(w);

            // industry

            const ind: any = {
                'current_value': 0,
                'predicted_value': 0
            };

            const i: any[] = [];
            i.push(ind);

            seddaResult[key] = {
                'company': x,
                'industry': i
            };
        }

        if (CORE_KPI_KEYS.indexOf(key) > -1) {
            const w: any = {
                'current_value': item.kpi_value,
                'predicted_value': 0
            };

            const x: any[] = [];
            x.push(w);

            // industry

            const ind: any = {
                'current_value': 0,
                'predicted_value': 0
            };

            const i: any[] = [];
            i.push(ind);

            seddaResult[key] = {
                'company': x,
                'industry': i
            };
        }
    });

    // Get company predicted values.
    companyPrognoses.forEach(function (item: any) {
        const key = item.kpi_key;

        if (CORE_KPI_KEYS.indexOf(key) > -1) {
            const existingItem = seddaResult[key]['company'][0];
            const source: any = {};
            source['predicted_value'] = item.kpi_prognosis_value;
            seddaResult[key]['company'][0] = Object.assign({}, existingItem, source);
        }
    });

    // Get industry current values 50th percentile.
    plcKpiAggs.forEach(function (item: any) {
        const key = item.kpi_key;

        if (CORE_KPI_KEYS.indexOf(key) > -1 && item.agg_key === '50%') {
            const existingItem = seddaResult[key]['industry'][0];
            const source: any = {};
            source['current_value'] = item.agg_value;
            seddaResult[key]['industry'][0] = Object.assign({}, existingItem, source);
        }
    });

    // Get industry predicted values.
    plcPrognoses.forEach(function (item: any) {
        const key = item.kpi_key;

        if (CORE_KPI_KEYS.indexOf(key) > -1) {
            const existingItem = seddaResult[key]['industry'][0];
            const source: any = {};
            source['predicted_value'] = item.kpi_prognosis_value;
            seddaResult[key]['industry'][0] = Object.assign({}, existingItem, source);
        }
    });

    function round(x: number, digits: number) {
        return parseFloat(x.toFixed(digits));
    }

    // Determine the domain/range for the plot.
    Object.keys(seddaResult).forEach(key => {
        const item = seddaResult[key];
        const xCo = item['company'][0]['current_value'];
        const xInd = item['industry'][0]['current_value'];
        const yCo = item['company'][0]['predicted_value'];
        const yInd = item['industry'][0]['predicted_value'];

        const xMin = Math.min(xInd, xCo, 0);
        const xMax = Math.max(xInd, xCo, 0);
        const yMin = Math.min(yInd, yCo, 0);
        const yMax = Math.max(yInd, yCo, 0);

        const minVal = Math.min(xMin, yMin, 0);
        const maxVal = Math.max(xMax, yMax);
        const minInd = Math.min(xInd, yInd);

        let xRange;
        let yRange;

        if (minVal < 0) {
            xRange = [round(minVal * 3, 2), round(maxVal * 1.5, 2)];
            yRange = [round(minVal * 3, 2), round(maxVal * 1.5, 2)];
        } else {
            xRange = [round(-minVal * 3, 2), round(maxVal * 1.5, 2)];
            yRange = [round(-minVal * 3, 2), round(maxVal * 1.5, 2)];
        }

        const existingItem = seddaResult[key];
        const source: any = {};
        source['xDomain'] = xRange;
        source['yDomain'] = yRange;
        source['xRef'] = minInd;
        source['yRef'] = minInd;
        seddaResult[key] = Object.assign({}, existingItem, source);
    });

    return seddaResult;
}