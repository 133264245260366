import * as React from 'react';
import {Link} from 'react-router-dom';
import {ClientRoutes} from "../clientRoutes";

import {ReactComponent as GenericCompany} from './generic-company.svg';

export default function NoReportsExist() {
    return (
        <div className="no-reports">
            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center generic-company">
                        <GenericCompany height={150}/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <h1 className="header-title text-center">No reports yet.</h1>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center text-body-tertiary mb-3">
                        Add a financial report to analyze your
                        competitive prognosis performance.
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center">
                        <Link to={ClientRoutes.reportsAdd} className="btn btn-primary btn-lg">
                            Add report
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}