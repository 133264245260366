import {createContext} from 'react';

export interface UserAuth {
    isAuth: () => boolean;
    signIn: (jwtToken: string, jwtExpiresIn: number) => void;
    signOut: () => void;
    // auth: boolean;
    getToken: () => string;

    setUser: (user: any) => void;
    getEntitlement: (entitlement_key: string) => any | null;
}

const defaultContext: UserAuth = {
    isAuth: () => false,
    signIn: () => {
    },
    signOut: () => {
    },
    // auth: false,
    getToken: () => '',

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setUser: (user) => {
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getEntitlement: (entitlement_key) => null
};

const AuthContext = createContext(defaultContext);

export {AuthContext};