import * as React from 'react';

export default function Logo() {
    return (
        <div className="logo">
            <picture>
                <img src={process.env.PUBLIC_URL + '/images/cfo-digital-logo.png'} alt="FinRatio CFOdigital logo"/>
            </picture>
        </div>
    );
}