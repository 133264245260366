import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from './AuthProvider';
import {ClientRoutes} from './features/clientRoutes';

function RouteGuard({children}: any) {
    const {isAuth} = useAuth();

    return isAuth() ? children : <Navigate to={ClientRoutes.userSignIn}/>;
}

export default RouteGuard;