import * as Yup from "yup";

export const companyValidationSchema = Yup.object().shape({
    company_name: Yup.string()
        .required('Company name is required.')
        .max(100, 'Company name must not exceed 100 characters.'),
    sector_key: Yup.string()
        .required('Sector is required'),
    subsector_key: Yup.string()
        .required('Subsector is required.'),
    country_code: Yup.string()
        .required('Country code is required.'),
    currency_code: Yup.string()
        .required('Currency is required.'),
    website_url: Yup.string().nullable()
        .max(255, 'Website URL must not exceed 255 characters.')
});