import {Company} from "./company";

export function getCurrentCompany(companyList: any[], companyId: string): Company | null {

    if (companyId === '') {
        return null;
    }

    let company = null;

    companyList.forEach(function (co: any) {
        if (co.company_id === companyId) {

            const c = new Company();
            c.fromJson(co);

            company = c;
            return;
        }
    });

    if (company === null) {
        console.warn('CompanyId not found in company list');
    }

    return company;
}