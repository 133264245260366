import {Currency} from "./currency";

export class CurrencyList {
    currencies: Currency[] = [];

    fromJson(json: any) {
        const results = json;
        const currencyList: Currency[] = [];

        results.forEach(function (item: any) {
            const currency = new Currency();
            currency.fromJson(item);
            currencyList.push(currency);
        });

        this.currencies = currencyList;
    }
}