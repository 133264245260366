export function parseNumber(val: string) {

    val = val.toString();

    // If value contains a dash, move it to the front.
    if (val.indexOf('-') > 0) {
        val = val
            .replace(/[^0-9.-]/g, '')       // remove chars except number, hyphen, point.
            .replace(/(\..*)\./g, '$1')     // remove multiple points.
            .replace(/(?!^)-/g, '')         // remove middle hyphen.
            .replace(/^0+(\d)/gm, '$1');    // remove multiple leading zeros.
        val = "-" + val;
    }

    val = val
        .replace(/[^0-9.-]/g, '')       // remove chars except number, hyphen, point.
        .replace(/(\..*)\./g, '$1')     // remove multiple points.
        .replace(/(?!^)-/g, '')         // remove middle hyphen.
        .replace(/^0+(\d)/gm, '$1');    // remove multiple leading zeros.

    if (val === '-') {
        return 0;
    }

    return Number(val);
}