import {useEffect, useState} from "react";
import {ApiRoutes} from "../../apiRoutes";
import {useAuth} from "../../AuthProvider";
import {postData} from "../../utils/postData";
import {getHeaders} from "../../utils/requestHeaders";
import {FeatureStatus} from "../featureStatus";

export default function useUserSignUp() {
    const {getToken} = useAuth();

    const [status, setStatus] = useState(FeatureStatus.IsReady);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async (data: any) => {
            if (status !== FeatureStatus.IsSubmitting) {
                return;
            }

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(FeatureStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(FeatureStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            const userResults = await postData(ApiRoutes.userSignUp, data, headers, signal);

            if (doExitEarly(userResults)) {
                return;
            }

            setStatus(FeatureStatus.HasSubmitted);
        };

        postForm(formData).then();

        return () => {
            controller.abort();
        };

    }, [status, formData, getToken]);

    const signUpUser = (data: any) => {
        setStatus(FeatureStatus.IsSubmitting);
        setMessage('');
        setFormData(data);
    };

    return {
        status, message, validationErrors, signUpUser
    };
}