import React, {useCallback, useContext, useMemo, useState} from 'react';
import {CompanyContext} from "./CompanyContext";
import {useLocalStorage} from "./utils/useLocalStorage";

const COMPANY_LIST_KEY = 'companies';
const COMPANY_ID_KEY = 'companyId';

const CompanyProvider = ({children}: any) => {

    const {getItem, setItem} = useLocalStorage();

    const [companyList, setCompanyList] = useState<any[]>(JSON.parse(getItem(COMPANY_LIST_KEY) || '[]'));
    const [companyId, setCompanyId] = useState<string>(getItem(COMPANY_ID_KEY) || '');

    const getCompanies = useCallback(() => {
        return companyList;
    }, [companyList]);

    const setCompanies = useCallback((companies: any[]) => {
        setItem(COMPANY_LIST_KEY, JSON.stringify(companies));
        setCompanyList(companies);
    }, [setItem, setCompanyList]);

    const getCurrentCompanyId = useCallback(() => {
        return companyId;
    }, [companyId]);

    const setCurrentCompanyId = useCallback((companyId: string) => {
        setItem(COMPANY_ID_KEY, companyId);
        setCompanyId(companyId);
    }, [setItem, setCompanyId]);

    const companyCtx = useMemo(() => ({
        getCompanies: getCompanies,
        setCompanies: setCompanies,
        getCurrentCompanyId: getCurrentCompanyId,
        setCurrentCompanyId: setCurrentCompanyId
    }), [getCompanies, setCompanies, getCurrentCompanyId, setCurrentCompanyId]);

    return (
        <CompanyContext.Provider value={companyCtx}>
            {children}
        </CompanyContext.Provider>
    );
};

const useCompany = () => {
    return useContext(CompanyContext);
};

export {CompanyProvider, useCompany};