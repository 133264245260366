import * as React from 'react';
import {Cell, Label, Pie, PieChart, ResponsiveContainer} from 'recharts';
import Colors from "../../colors";
import {FeatureStatus} from "../../featureStatus";

// TODO: Replace with utils.
function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
}

function LoadingChart({description1, description2}: any) {
    return (
        <>
            <h5 className="card-title">
                <div>{description1}</div>
                <div>{description2}</div>
            </h5>
            <div className="charts-loading text-center">
                    <span className="spinner-border spinner-border-lg text-primary"
                          role="status"
                          aria-hidden="true"></span>
            </div>
        </>
    );
}

function Chart({data}: any) {
    return (
        <>
            <ResponsiveContainer width="100%" height={160}>
                <PieChart width={400} height={300}>
                    <Pie
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={data}
                        cx="50%"
                        cy="80%"
                        innerRadius={80}
                        outerRadius={100}
                        fill="#white"
                        labelLine={true}
                        stroke="black"
                        strokeWidth={1}
                        paddingAngle={3}
                    >
                        <Label
                            value={data[0].value}
                            position="center"
                            fill="grey"
                            dy={-20}
                            style={{
                                fontSize: "32px",
                                fontWeight: "bold"
                            }}
                        />

                        {data.map((entry: any, index: any) => (
                            <Cell
                                key={`cell-${index}`}
                                stroke={index === 0 ? "none" : "none"}
                                strokeWidth={1}
                                fill={entry.color}/>
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}

export default function CcpChart({status, data, kpiKey}: any) {

    const isLoading = status === FeatureStatus.IsLoading;
    const isDataReady = data !== undefined && !isEmpty(data);

    let title = 'Generating analysis...';
    let myData: any[] = [];

    let template;

    if (isLoading) {
        const description1 = 'Generating analysis...';
        const description2 = '\u00A0';

        template = <LoadingChart description1={description1} description2={description2}/>;

    } else if (!isLoading && isDataReady) {

        // Need two values which sum = 100.
        title = data[kpiKey].kpi_name + ' CCP Score';
        const cpp = data[kpiKey].kpi_value;
        const remainder = (100 - data[kpiKey].kpi_value);

        myData = [
            {name: 'A', value: cpp, color: Colors.primary},
            {name: 'B', value: remainder, color: Colors.primaryMuted}
        ];

        template = <Chart data={myData}/>;
    } else {
        title = 'CCP Score';
        template = <div>No data available</div>;
        console.warn('No CCP data available');
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                {template}
            </div>
        </div>
    );
}
