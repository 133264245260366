export class Kpi {
    kpiKey: string = '';
    kpiTransformationKey: string | null = null;
    kpiName: string = '';
    kpiValue: number | null = null;
    kpiCategoryKey: string = '';
    kpiCategoryName: string = '';

    fromJson(json: any) {
        this.kpiKey = json.kpi_key;
        this.kpiTransformationKey = json.kpi_transformation;
        this.kpiName = json.kpi_name;
        this.kpiValue = json.kpi_value;
        this.kpiCategoryKey = json.kpi_category_key;
        this.kpiCategoryName = json.kpi_category_name;
    }
}