import * as React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';

const COLORS = [
    '#2c7be5',
    '#7CC674',
    '#73C5C5',
    '#8481DD',
    '#F6D173',
    '#EF9234',
    '#a2a2a2'
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default function BalanceSheetIndustryChart({title, data}: any) {
    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                <ResponsiveContainer width="100%" height={300}>
                    <PieChart width={400} height={400}>
                        <Pie data={data}
                             dataKey="benchmark_value"
                             labelLine={false}
                             label={renderCustomizedLabel}
                             nameKey="benchmark_name"
                             startAngle={450}
                             endAngle={90}
                             cx="50%"
                             cy="50%">
                            {
                                data?.map((item: any, index: any) => (
                                    <Cell key={item.benchmark_key} fill={COLORS[index % COLORS.length]}/>
                                ))
                            }
                        </Pie>
                        <Legend
                            layout="horizontal"
                            align="center"
                            verticalAlign="bottom"
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}