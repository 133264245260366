import {Country} from "./country";

export class CountryList {
    countries: Country[] = [];

    fromJson(json: any) {
        const results = json;
        const countryList: Country[] = [];

        results.forEach(function (item: any) {
            const country = new Country();
            country.fromJson(item);
            countryList.push(country);
        });

        this.countries = countryList;
    }
}