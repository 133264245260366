import * as React from 'react';
import {Link} from 'react-router-dom';

import {ClientRoutes} from '../clientRoutes';

export default function NotFound() {
    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-md-5 col-lg-4">
                    <h1 className="card-title">Page not found.</h1>
                    <p>Sorry, an error has occurred. The requested resource was not found.</p>
                    <div className="row">
                        <div className="col-6">
                            <Link to={ClientRoutes.home} className="float-start">Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}