import React from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import './App.css';
import {useCompany} from "./CompanyProvider";
import {ClientRoutes} from "./features/clientRoutes";
import Menu from './menu';

const hiddenOn = [
    ClientRoutes.userChangePasswordConfirmation,
    ClientRoutes.userChangePasswordRequest,
    ClientRoutes.userChangePasswordStatus,
    ClientRoutes.userEmailVerification,
    ClientRoutes.userEmailVerificationRequest,
    ClientRoutes.userEmailVerificationStatus,
    ClientRoutes.userSignIn,
    ClientRoutes.userSignOut,
    ClientRoutes.userSignUp,
    ClientRoutes.notFound,
    ClientRoutes.publicPricing,
    ClientRoutes.publicPrivacyPolicy,
    ClientRoutes.publicTermsOfUse
];

const App = () => {
    const location = useLocation();

    const {getCurrentCompanyId} = useCompany();
    const companyId = getCurrentCompanyId();
    console.log('APP companyId: ' + companyId);

    let menuElement = <></>;

    if (!hiddenOn.includes(location.pathname)) {
        menuElement = (
            <div className="menu col-sm-auto sticky-top">
                <Menu/>
            </div>
        );
    }

    const year = new Date().getFullYear();

    return (
        <div className="container-fluid overflow-hidden">
            <div className="row vh-100 overflow-auto">
                {/*<div className="menu col-12 col-sm-3 col-xl-2 px-sm-2 px-0 d-flex sticky-top">*/}
                {/*    <Menu/>*/}
                {/*</div>*/}

                {menuElement}

                {/*<div className="col d-flex flex-column h-sm-100">*/}

                <div className="col d-flex flex-column h-100">
                    <main className="row overflow-auto flex-shrink-0">
                        <Outlet/>
                    </main>

                    <footer className="row footer mt-auto px-3 py-3">
                        <div className="col">&copy; {year} FinRatio LLC. All rights reserved.</div>
                        <div className="col-auto">
                            <Link to={ClientRoutes.home}>
                                Home
                            </Link>
                            <span className="divider">|</span>
                            <Link to={ClientRoutes.publicTermsOfUse}>
                                Terms of Use
                            </Link>
                            <span className="divider">|</span>
                            <Link to={ClientRoutes.publicPrivacyPolicy}>
                                Privacy Policy
                            </Link>
                        </div>
                    </footer>

                    {/*<footer className="row py-4 mt-auto">*/}
                    {/*    <div className="col">Copyright 2024 FinRatio LLC. All rights reserved.</div>*/}
                    {/*</footer>*/}
                </div>

                {/*<main className="main col-sm min-vh-100">*/}
                {/*    <Outlet/>*/}
                {/*</main>*/}

                {/*<div className="test">*/}
                {/*    <div className="d-block d-sm-none">Extra Small (xs)</div>*/}
                {/*    <div className="d-none d-sm-block d-md-none">Small (sm)</div>*/}
                {/*    <div className="d-none d-md-block d-lg-none">Medium (md)</div>*/}
                {/*    <div className="d-none d-lg-block d-xl-none">Large (lg)</div>*/}
                {/*    <div className="d-none d-xl-block d-xxl-none">X-Large (xl)</div>*/}
                {/*    <div className="d-none d-xxl-block">XX-Large (xxl)</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default App;
