import * as React from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    LabelList,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {round} from "../../../utils/formatDecimal";

import Colors from '../../colors';

const COLORS = [
    Colors.primary,
    '#2362b7',
    '#BDE2B9',
    '#7CC674',
    '#4CB140',
    '#38812F'
];

const customLabel = ({x, y, payload}: any) => {

    const rows = payload.value.split('\n');

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill={Colors.textMedium} fontSize={13}>
                <tspan textAnchor="middle" x="0">{rows[0]}</tspan>
                <tspan textAnchor="middle" x="0" dy="20">{rows[1]}</tspan>
            </text>
        </g>
    );
};

function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
}

function getMaxValue(obj: any[]) {

    let value = 0;

    obj.forEach(function (item) {
        if (item.value > value) {
            value = item.value;
        }
    });

    return value;
}

function getMinValue(obj: any[]) {

    let value = 0;

    obj.forEach(function (item) {
        if (item.value < value) {
            value = item.value;
        }
    });

    return value;
}

export default function PrognosisIndustryChart({title, prognosesVsIndustry, kpiKey}: any) {

    const isNotReady = (prognosesVsIndustry === undefined || isEmpty(prognosesVsIndustry));
    let data: any = {};

    let yDomainMin = 0;
    let yDomainMax = 0;

    if (!isNotReady) {
        data = prognosesVsIndustry[kpiKey];

        const minVal = getMinValue(data);
        yDomainMin = minVal * 1.15;
        yDomainMin = round(yDomainMin, 3);

        const maxVal = getMaxValue(data);
        yDomainMax = maxVal * 1.15;
        yDomainMax = round(yDomainMax, 3);
    }

    return (
        isNotReady ? <></> :

            <div className="card">
                <div className="card-header">
                    <h3>{title}</h3>
                </div>
                <div className="card-body">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            width={300}
                            height={300}
                            data={data}
                            // tickCount={6}
                            margin={{
                                top: 20,
                                right: 0,
                                left: -15,
                                bottom: 25
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis
                                dataKey="name"
                                tick={customLabel}
                                interval={0}
                                color={Colors.textMedium}/>
                            <YAxis
                                stroke={Colors.textMedium}
                                tick={{fontSize: 12}}
                                type="number"
                                domain={[yDomainMin, yDomainMax]}/>
                            <Tooltip/>
                            <ReferenceLine y={0} stroke={Colors.lineMedium}/>

                            <Bar dataKey="value" barSize={35}>
                                <LabelList
                                    dataKey="value"
                                    fill={Colors.textMedium}
                                    position="top"
                                />
                                {
                                    data.map((_entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
    );
}