import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useNavigate} from 'react-router-dom';
import {getFieldToFocus} from "../../utils/pageUtils";
import {ClientRoutes} from '../clientRoutes';
import FormError from "../common/FormError";
import Logo from '../common/Logo';
import SubmitButton from "../common/SubmitButton";
import {FeatureStatus} from "../featureStatus";
import {PageTitles} from '../pageTitles';
import {userValidationSchema} from "./userValidationSchema";
import useUserSignUp from "./useUserSignUp";

export default function UserSignUp() {
    const {status, message, validationErrors, signUpUser} = useUserSignUp();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setError,
        setFocus,
        getValues,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;

    useEffect(() => {
        document.title = PageTitles.userSignUp;
    }, []);

    useEffect(() => {
        if (status === FeatureStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, 'email_address');
            setFocus(fieldName);
        } else if (status === FeatureStatus.IsReady) {
            setFocus('email_address');
        }
    }, [status, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (status === FeatureStatus.HasSubmitted) {
            const emailAddress = getValues("email_address");
            navigate(ClientRoutes.userEmailVerificationStatus, {state: {'emailAddress': emailAddress}});
        }
    }, [status, getValues, navigate]);

    const onSubmitHandler = async (data: any) => {
        signUpUser(data);
    };

    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <Logo/>
                    <div className="card">
                        <div className="card-body">
                            <h1 className="card-title">Sign up.</h1>
                            <FormError status={status} message={message}/>
                            <form onSubmit={handleSubmit(onSubmitHandler)}>
                                <fieldset id="me" disabled={status === FeatureStatus.IsSubmitting}>
                                    <div className="form-floating">
                                        <input {...register('email_address')}
                                               type="text"
                                               className="form-control"
                                               id="floatingEmail"
                                               placeholder="name@example.com"/>
                                        <label htmlFor="floatingEmail">Email address</label>
                                        <small className="text-danger">
                                            {errors?.email_address?.message?.toString()}
                                        </small>
                                    </div>
                                    <div className="form-floating">
                                        <input {...register('password')}
                                               type="password"
                                               className="form-control"
                                               id="floatingPassword"
                                               placeholder="Password"/>
                                        <label htmlFor="floatingPassword">Password</label>
                                        <small className="text-danger">
                                            {errors?.password?.message?.toString()}
                                        </small>
                                    </div>
                                    <div>
                                        <SubmitButton
                                            isFormReady={isFormReady}
                                            featureStatus={status}
                                            text="Create account"
                                        />
                                    </div>
                                </fieldset>
                            </form>
                            <div className="row">
                                <div className="col-4">
                                    <Link to={ClientRoutes.userSignIn} className="float-start">
                                        Sign in
                                    </Link>
                                </div>
                                <div className="col-8">
                                    <Link to={ClientRoutes.userChangePasswordRequest} className="float-end">
                                        Forgot password
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}