import {ReportEntry} from "./reportEntry";

export class ReportStatement {
    statementKey: string = '';
    statementName: string = '';
    entries: ReportEntry[] = [];

    fromJson(json: any) {
        this.statementKey = json.statement_key;
        this.statementName = json.statement_name;

        const entries = json.entries;
        const entryList: ReportEntry[] = [];

        entries.forEach(function (entry: any) {
            const reportEntry = new ReportEntry();
            reportEntry.fromJson(entry);
            entryList.push(reportEntry);
        });

        this.entries = entryList;
    }

    getCategoryEntries(accountCategory: string) {
        const entries: ReportEntry[] = [];

        this.entries.forEach(function (e: ReportEntry) {
            if (e.accountCategory === accountCategory) {
                entries.push(e);
            }
        });

        return entries;
    }

    getTotal(items: ReportEntry[]) {
        let total = 0;

        items.forEach(function (item: ReportEntry) {
            total += item.amount;
        });

        return total;
    }

    getAssets() {
        return this.getCategoryEntries('assets');
    }

    getAssetsTotal() {
        return this.getTotal(this.getAssets());
    }

    getCashflows() {
        return this.getCategoryEntries('cashflows');
    }

    getCashflowsTotal() {
        return this.getTotal(this.getCashflows());
    }

    getLiabilities() {
        return this.getCategoryEntries('liabilities');
    }

    getLiabilitiesTotal() {
        return this.getTotal(this.getLiabilities());
    }

    getEquity() {
        return this.getCategoryEntries('equity');
    }

    getEquityTotal() {
        return this.getTotal(this.getEquity());
    }

    getRevenues() {
        return this.getCategoryEntries('revenue');
    }
}