export function getHeaders(token: string) {

    if (token) {
        return {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
    }

    return {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    };
}
