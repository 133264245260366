import {yupResolver} from "@hookform/resolvers/yup";
import * as React from "react";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import {useCompany} from "../../../CompanyProvider";
import {getFieldToFocus} from "../../../utils/pageUtils";
import {ClientRoutes} from "../../clientRoutes";
import FormError from "../../common/FormError";
import FormSuccess from "../../common/FormSuccess";
import SubmitButton from "../../common/SubmitButton";
import {FeatureStatus} from "../../featureStatus";
import {companyValidationSchema} from "../companyValidation";
import {Country} from "../country";
import {Currency} from "../currency";
import useCompanySave from "../useCompanySave";
import useCountryList from "../useCountryList";
import useCurrencyList from "../useCurrencyList";
import useIndustryList from "../useIndustryList";

export default function CompanyEdit({companyId}: any) {
    const navigate = useNavigate();

    const {status: industryStatus, message: industryMessage, industryList} = useIndustryList();
    const {status: currencyStatus, message: currencyMessage, currencyList} = useCurrencyList();
    const {status: countryStatus, message: countryMessage, countryList} = useCountryList();
    const {status: saveStatus, message: saveMessage, validationErrors, saveCompany} = useCompanySave(companyId);

    const getStatus = (statuses: FeatureStatus[]) => {
        if (statuses.includes(FeatureStatus.RequiresAuth)) {
            return FeatureStatus.RequiresAuth;
        }

        if (statuses.includes(FeatureStatus.HasError)) {
            return FeatureStatus.HasError;
        }

        if (statuses.includes(FeatureStatus.IsLoading)) {
            return FeatureStatus.IsLoading;
        }

        if (statuses.includes(FeatureStatus.IsSubmitting)) {
            return FeatureStatus.IsSubmitting;
        }

        if (statuses.includes(FeatureStatus.HasSubmitted)) {
            return FeatureStatus.HasSubmitted;
        }

        return FeatureStatus.IsReady;
    };

    const getMessage = (messages: string[]) => {
        let msg = '';

        messages.forEach(function (m: string) {
            if (m) {
                msg += ' ' + m;
            }
        });

        return msg;
    };

    const status = getStatus([industryStatus, currencyStatus, countryStatus, saveStatus]);
    const message = getMessage([industryMessage, currencyMessage, countryMessage, saveMessage]);
    const isBusy: boolean = (status === FeatureStatus.IsLoading || status === FeatureStatus.IsSubmitting);
    const [selectedSector, setSelectedSector] = React.useState('');

    let sectorKeys: string[] = [];
    let subsectorKeys = null;

    if (industryList) {
        sectorKeys = industryList.getSectorKeys();

        if (selectedSector !== '') {
            subsectorKeys = industryList.getSubsectorKeys(selectedSector);
        }
    }

    const {getCompanies} = useCompany();

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(companyValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;
    const submitText = companyId === '' ? 'Add company' : 'Update company';

    useEffect(() => {
        if (status === FeatureStatus.RequiresAuth) {
            navigate(ClientRoutes.userSignOut);
        }
    }, [navigate, status, industryStatus, currencyStatus, countryStatus]);

    useEffect(() => {
        if (industryList && currencyList && countryList) {
            const existingCompanies = getCompanies();

            // After loading utility lists, load existing, selected company.
            existingCompanies.forEach(function (item) {
                if (companyId === item.company_id) {
                    setSelectedSector(item.sector_key);
                    reset(item);
                    return;
                }
            });
        }
    }, [industryList, currencyList, countryList, companyId, getCompanies, reset]);

    useEffect(() => {
        if (status === FeatureStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, 'company_name');
            setFocus(fieldName);

            document.getElementById('companyRoot')?.scrollIntoView({behavior: 'smooth'});

        } else if (status === FeatureStatus.IsReady) {
            setFocus('company_name');
        }
    }, [status, validationErrors, setError, setFocus]);

    const onSubmitHandler = async (data: any) => {
        saveCompany(data);
    };

    // Company was saved. Make sure header reloads and URL changes.
    useEffect(() => {
        if (status === FeatureStatus.HasSubmitted && companyId === '') {
            navigate(ClientRoutes.company);
        }

    }, [status, companyId, navigate]);

    const onCancelClick = (e: any) => {
        e.preventDefault();
        navigate(ClientRoutes.home);
    };

    const handleSectorChange = (event: any) => {
        setSelectedSector(event.target.value);
    };

    return (
        <div className="row">
            <div className="col">
                <FormError status={status} message={message}/>
                <FormSuccess status={status} message={message}/>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <fieldset id="me" disabled={isBusy}>
                        <input {...register("company_id", {value: ""})} type="hidden"/>
                        <div>
                            <label
                                htmlFor="companyName"
                                className="form-label">
                                Company name
                            </label>
                            <input
                                {...register('company_name')}
                                type="text" id="companyName"
                                className="form-control"
                                placeholder="Example Inc."/>
                            <small className="text-danger">
                                {errors?.company_name?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label
                                htmlFor="sectorKey"
                                className="form-label">
                                Sector
                            </label>
                            <select
                                {...register('sector_key')}
                                id="sectorKey"
                                className="form-select"
                                defaultValue=""
                                onChange={handleSectorChange}>
                                <option key="" value="">Select sector...</option>
                                {
                                    sectorKeys.map(key => (
                                        <option key={key} value={key}>
                                            {industryList && industryList.getSectorNameByKey(key)}
                                        </option>
                                    ))
                                }
                            </select>
                            <small className="text-danger">
                                {errors?.sector_key?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="subsectorKey" className="form-label">
                                Subsector
                            </label>
                            <select
                                {...register('subsector_key')}
                                id="subsectorKey"
                                className="form-select"
                                defaultValue=""
                                disabled={subsectorKeys === null}>
                                <option key="" value="">Select subsector...</option>
                                {
                                    subsectorKeys ? subsectorKeys.map((key: any) =>
                                        <option value={key} key={key}>
                                            {industryList && industryList.getSectorNameByKey(key)}
                                        </option>
                                    ) : <></>
                                }
                            </select>
                            <small className="text-danger">
                                {errors?.subsector_key?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="companyCurrency" className="form-label">Currency</label>
                            <input
                                {...register('currency_code')}
                                className="form-control"
                                list="companyCurrencyOptions"
                                id="companyCurrency"
                                placeholder="Type to search..."/>
                            <datalist id="companyCurrencyOptions">
                                {
                                    currencyList && currencyList.currencies.map((e: Currency) =>
                                        <option
                                            value={e.currencyCode}
                                            key={e.currencyCode}>{e.currencyName}
                                        </option>)
                                }
                            </datalist>
                            <small className="text-danger">
                                {errors?.currency_code?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="companyCountry" className="form-label">Country code</label>
                            <input
                                {...register('country_code')}
                                className="form-control"
                                list="companyCountryOptions"
                                id="companyCountry"
                                placeholder="Type to search..."/>
                            <datalist id="companyCountryOptions">
                                {
                                    countryList && countryList.countries.map((e: Country) =>
                                        <option
                                            value={e.countryCode}
                                            key={e.countryCode}>{e.countryName}
                                        </option>)
                                }
                            </datalist>
                            <small className="text-danger">
                                {errors?.country_code?.message?.toString()}
                            </small>
                        </div>

                        <div>
                            <label htmlFor="websiteUrl" className="form-label">Website</label>
                            <input
                                {...register('website_url')}
                                type="text"
                                id="websiteUrl"
                                className="form-control"
                                placeholder="https://www.example.com"/>
                            <small className="text-danger">
                                {errors?.website_url?.message?.toString()}
                            </small>
                        </div>
                        <div>
                            <hr/>
                        </div>
                        <div>
                            <SubmitButton
                                isFormReady={isFormReady}
                                featureStatus={status}
                                text={submitText}
                            />
                            {
                                companyId === '' &&
                                (
                                    <button className="btn btn-lg btn-outline-secondary"
                                            onClick={onCancelClick}>
                                        Cancel
                                    </button>
                                )
                            }
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}