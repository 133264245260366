import * as React from 'react';
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';

import Colors from '../../colors';

const customLabel = ({x, y, payload}: any) => {

    payload.value = payload.value.replaceAll(' ', '\n');
    payload.value = payload.value.replace('Turnover', 'Turn');

    const rows = payload.value.split('\n');

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill={Colors.textMedium} fontSize={12}>
                <tspan textAnchor="middle" x="0">{rows[0]}</tspan>
                <tspan textAnchor="middle" x="0" dy="15">{rows[1]}</tspan>
                <tspan textAnchor="middle" x="0" dy="15">{rows[2]}</tspan>
            </text>
        </g>
    );
};

export default function KpiAggChart({title, kpisVsIndustry, categoryKey}: any) {

    return (
        <div className="card">
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                        width={400}
                        height={400}
                        data={kpisVsIndustry[categoryKey]}
                        margin={{top: 20, right: 20, bottom: 20, left: -10}}
                    >
                        <CartesianGrid stroke={Colors.lightLine}/>
                        <XAxis
                            dataKey="kpi_name"
                            interval={0}
                            padding={{left: 30, right: 30}}
                            tick={customLabel}
                        />
                        <YAxis
                            stroke={Colors.textMedium}
                            tick={{fontSize: 12}}
                        />
                        <Tooltip/>
                        <Legend
                            align="center"
                            iconSize={10}
                            verticalAlign="bottom"
                            wrapperStyle={{
                                paddingTop: "30px"
                            }}
                        />

                        {
                            kpisVsIndustry && (categoryKey in kpisVsIndustry) && (kpisVsIndustry[categoryKey]).map((item: any) => {
                                return (<ReferenceLine key={item.kpi_key} stroke={Colors.secondary} segment={[{
                                    x: item.kpi_name,
                                    y: item.agg_25_value
                                }, {x: item.kpi_name, y: item.agg_75_value}]}/>);
                            })
                        }

                        <Scatter
                            dataKey="agg_25_value"
                            name="25th Percentile"
                            fill={Colors.secondary}
                            shape="square"
                            legendType="square"/>
                        <Scatter
                            dataKey="agg_50_value"
                            name="50th Percentile"
                            fill={Colors.secondary}/>
                        <Scatter
                            dataKey="agg_75_value"
                            name="75th Percentile"
                            fill={Colors.secondary}
                            shape="triangle"
                            legendType="triangle"/>

                        <Line dataKey="kpi_value" name="Company" stroke={Colors.primary} strokeWidth={2}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
