import * as React from 'react';
import {
    ComposedChart,
    Label,
    Legend,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import Colors from "../../colors";

function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
}

const TriangleReferenceArea = (props: any) => {

    let p;

    if (props.direction === 'up') {
        p = props.x + "," + props.y + " " + (props.x + props.width) + "," + props.y + " " + props.x + "," + (props.y + props.height);
    } else {
        const p1 = `${props.x},${(props.y + props.height)}`;
        const p2 = `${(props.x + props.width)},${props.y}`;
        const p3 = `${(props.x + props.width)},${(props.y + props.height)}`;

        p = `${p1} ${p2} ${p3}`;
    }

    return (
        <polygon
            points={p}
            fill={props.fill}
            fillOpacity={props.fillOpacity}
        />
    );
};

export default function SeddaChart({title, seddaKpis, kpiKey}: any) {

    const isNotReady = (seddaKpis === undefined || isEmpty(seddaKpis));

    return (
        isNotReady ? <></> :

            <div className="card">
                <div className="card-header">
                    <h3>{title}</h3>
                </div>
                <div className="card-body">
                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart
                            width={400}
                            height={400}
                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20
                            }}
                        >
                            <Legend
                                iconSize={12}
                                align="center"
                                verticalAlign="bottom"
                                wrapperStyle={{position: 'relative', marginTop: '-5px'}}
                            >
                            </Legend>

                            <XAxis
                                type="number"
                                dataKey="current_value"
                                domain={seddaKpis[kpiKey]['xDomain']}
                                stroke={Colors.textMedium}
                                tick={{fontSize: 12}}
                                tickCount={5}
                            >
                                <Label dx={-10} dy={20}>Current Values</Label>
                            </XAxis>

                            <YAxis
                                type="number"
                                dataKey="predicted_value"
                                domain={seddaKpis[kpiKey]['yDomain']}
                                stroke={Colors.textMedium}
                                tick={{fontSize: 12}}
                                tickCount={5}>
                                <Label transform="rotate(270 40 145)">Prognosis</Label>
                            </YAxis>

                            <Tooltip/>

                            <ReferenceArea
                                x1={seddaKpis[kpiKey]['xDomain'][0]}
                                x2={seddaKpis[kpiKey]['xRef']}
                                y1={seddaKpis[kpiKey]['yRef']}
                                y2={seddaKpis[kpiKey]['yDomain'][1]}
                                fill="green"
                                fillOpacity={0.1}
                                stroke="none"
                            >
                                <Label dx={10} position="insideLeft">Encouraging</Label>
                            </ReferenceArea>

                            <ReferenceArea
                                x1={seddaKpis[kpiKey]['xRef']}
                                x2={seddaKpis[kpiKey]['xDomain'][1]}
                                y1={seddaKpis[kpiKey]['yRef']}
                                y2={seddaKpis[kpiKey]['yDomain'][1]}
                                fill="green"
                                fillOpacity={0.5}
                                stroke="none"
                                shape={<TriangleReferenceArea direction="up"/>}
                            >
                                <Label dx={10} dy={10} position="insideTopLeft">Sound</Label>
                            </ReferenceArea>

                            <ReferenceArea
                                x1={seddaKpis[kpiKey]['xRef']}
                                x2={seddaKpis[kpiKey]['xDomain'][1]}
                                y1={seddaKpis[kpiKey]['yRef']}
                                y2={seddaKpis[kpiKey]['yDomain'][1]}
                                fill="yellow"
                                fillOpacity={0.5}
                                stroke="none"
                                shape={<TriangleReferenceArea direction="down"/>}
                            >
                                <Label dx={-10} position="insideBottomRight">Disquieting</Label>
                            </ReferenceArea>

                            <ReferenceArea
                                x1={seddaKpis[kpiKey]['xRef']}
                                x2={seddaKpis[kpiKey]['xDomain'][1]}
                                y1={seddaKpis[kpiKey]['yRef']}
                                y2={seddaKpis[kpiKey]['yDomain'][0]}
                                fill="orange"
                                fillOpacity={0.3}
                                stroke="none"
                            >
                                <Label dx={-10} position="insideRight">Dismaying</Label>
                            </ReferenceArea>

                            <ReferenceArea
                                x1={seddaKpis[kpiKey]['xDomain'][0]}
                                x2={seddaKpis[kpiKey]['xRef']}
                                y1={seddaKpis[kpiKey]['yDomain'][0]}
                                y2={seddaKpis[kpiKey]['yRef']}
                                fill="red"
                                fillOpacity={0.1}
                                stroke="none"
                                shape={<TriangleReferenceArea direction="down"/>}
                            >
                                <Label dx={-10} position="insideRight">Ailing</Label>
                            </ReferenceArea>

                            <ReferenceArea
                                x1={seddaKpis[kpiKey]['xDomain'][0]}
                                x2={seddaKpis[kpiKey]['xRef']}
                                y1={seddaKpis[kpiKey]['yDomain'][0]}
                                y2={seddaKpis[kpiKey]['yRef']}
                                fill="green"
                                fillOpacity={0.1}
                                stroke="none"
                                shape={<TriangleReferenceArea direction="up"/>}
                            >
                                <Label dx={10} position="insideLeft"></Label>
                            </ReferenceArea>

                            <ReferenceLine x={seddaKpis[kpiKey]['xRef']} stroke="gray"/>
                            <ReferenceLine y={seddaKpis[kpiKey]['yRef']} stroke="gray"/>

                            <ReferenceLine stroke="gray" segment={[{
                                x: seddaKpis[kpiKey]['xDomain'][0],
                                y: seddaKpis[kpiKey]['yDomain'][0]
                            }, {
                                x: seddaKpis[kpiKey]['xDomain'][1],
                                y: seddaKpis[kpiKey]['yDomain'][1]
                            }]}
                            />

                            <Scatter name="Company" data={seddaKpis[kpiKey]['company']} fill={Colors.primary}/>
                            <Scatter name="Industry" data={seddaKpis[kpiKey]['industry']} fill={Colors.secondary}/>

                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
    );
}
