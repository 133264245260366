import * as React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BalanceSheetIndustryChart from "./BalanceSheetIndustryChart";
import KpiAggChart from "./KpiAggChart";
import PrognosisIndustryChart from "./PrognosisIndustryChart";
import SeddaChart from "./SeddaChart";
import CcpChart from "./CcpChart";

export default function AnalysisCharts({status, chartData}: any) {

    // Fetched data in correct shape.
    const companyCcp = chartData.companyCcp;
    const companyAssets = chartData.companyAssets;
    const companyLiabilities = chartData.companyLiabilities;
    const industryAssets = chartData.industryAssets;
    const industryLiabilities = chartData.industryLiabilities;
    const prognosesVsIndustry = chartData.prognosesVsIndustry;
    const kpisVsIndustry = chartData.kpisVsIndustry;
    const seddaKpis = chartData.seddaKpis;

    return (
        <div>
            <Tabs
                defaultActiveKey="cpp"
                id="analysis-reports-tab"
            >
                <Tab eventKey="cpp" title="Competitive Prognosis">
                    <div className="row">
                        <div className="col">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <span className="fw-bold">and</span> will
                                be at producing profits?
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <CcpChart status={status} data={companyCcp} kpiKey="net_margin"/>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <CcpChart status={status} data={companyCcp} kpiKey="ebit"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <span className="fw-bold">and</span> will
                                be at managing assets to turn profits?
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <CcpChart status={status} data={companyCcp} kpiKey="roa"/>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">

                            <CcpChart status={status} data={companyCcp} kpiKey="roe"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <br/><span className="fw-bold">and</span> will be at
                                improving liquidity?
                            </div>
                            <CcpChart status={status} data={companyCcp} kpiKey="cash_ratio"/>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <div className="alert alert-brand text-center" role="alert">
                                How good company is <br/><span className="fw-bold">and</span> will be at
                                producing higher-quality cashflows?
                            </div>
                            <CcpChart status={status} data={companyCcp} kpiKey="ocg"/>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="prognosis" title="Prognosis vs Industry">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <PrognosisIndustryChart
                                title="Net Margin"
                                prognosesVsIndustry={prognosesVsIndustry}
                                kpiKey="net_margin"/>
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <PrognosisIndustryChart
                                title="Operating Margin"
                                prognosesVsIndustry={prognosesVsIndustry}
                                kpiKey="ebit"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <PrognosisIndustryChart
                                title="ROA"
                                prognosesVsIndustry={prognosesVsIndustry}
                                kpiKey="roa"/>
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <PrognosisIndustryChart
                                title="ROE"
                                prognosesVsIndustry={prognosesVsIndustry}
                                kpiKey="roe"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <PrognosisIndustryChart
                                title="Cash Ratio"
                                prognosesVsIndustry={prognosesVsIndustry}
                                kpiKey="cash_ratio"/>
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <PrognosisIndustryChart
                                title="OCG (Operating Cash Generation)"
                                prognosesVsIndustry={prognosesVsIndustry} kpiKey="ocg"/>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="kpis" title="KPIs vs Industry">
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggChart
                                title="Liquidity Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'liquidity'}></KpiAggChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggChart
                                title="Days Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'days'}></KpiAggChart>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggChart
                                title="Turnover Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'turnover'}></KpiAggChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggChart
                                title="Return Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'return'}></KpiAggChart>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggChart
                                title="Profitability Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'profitability'}></KpiAggChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggChart
                                title="Leverage Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'leverage'}></KpiAggChart>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggChart
                                title="Quality of Cashflow Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'cashflow'}></KpiAggChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggChart
                                title="Liabilities to Yield Curve Ratios"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'lyca'}></KpiAggChart>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <KpiAggChart
                                title="Inflation Adjusted Inventory Carry-on Cost"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'iaicoc'}></KpiAggChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <KpiAggChart
                                title="ROA-to-Bond Ratio"
                                kpisVsIndustry={kpisVsIndustry}
                                categoryKey={'roa2bond'}></KpiAggChart>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="sedda" title="SEDDA">
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SeddaChart
                                title="Net Margin"
                                seddaKpis={seddaKpis}
                                kpiKey={'net_margin'}></SeddaChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <SeddaChart
                                title="Operating Margin"
                                seddaKpis={seddaKpis}
                                kpiKey={'ebit'}></SeddaChart>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SeddaChart
                                title="ROA"
                                seddaKpis={seddaKpis}
                                kpiKey={'roa'}></SeddaChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <SeddaChart
                                title="ROE"
                                seddaKpis={seddaKpis}
                                kpiKey={'roe'}></SeddaChart>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <SeddaChart
                                title="Cash Ratio"
                                seddaKpis={seddaKpis}
                                kpiKey={'cash_ratio'}></SeddaChart>
                        </div>
                        <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                            <SeddaChart
                                title="OCG (Operating Cash Generation)"
                                seddaKpis={seddaKpis}
                                kpiKey={'ocg'}></SeddaChart>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="balanceSheet" title="Balance Sheet vs Industry">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <BalanceSheetIndustryChart title="Company Assets" data={companyAssets}/>
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <BalanceSheetIndustryChart title="Industry Assets" data={industryAssets}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <BalanceSheetIndustryChart
                                title="Company Liabilities + Equity"
                                data={companyLiabilities}/>
                        </div>
                        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                            <BalanceSheetIndustryChart
                                title="Industry Liabilities + Equity"
                                data={industryLiabilities}/>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}