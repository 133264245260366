import {yupResolver} from '@hookform/resolvers/yup';
import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {CustomizedState} from '../../utils/customizedState';
import {getFieldToFocus} from "../../utils/pageUtils";
import {ClientRoutes} from "../clientRoutes";
import FormError from "../common/FormError";
import FormSuccess from "../common/FormSuccess";
import Logo from "../common/Logo";
import SubmitButton from "../common/SubmitButton";
import {FeatureStatus} from "../featureStatus";
import {PageTitles} from '../pageTitles';
import {userValidationSchema} from "./userValidationSchema";
import useUserSignIn from "./useUserSignIn";

export default function UserSignIn() {
    const {status, message, validationErrors, signInUser} = useUserSignIn();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;

    useEffect(() => {
        document.title = PageTitles.userSignIn;
    }, []);

    useEffect(() => {
        if (status === FeatureStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, 'email_address');
            setFocus(fieldName);
        } else if (status === FeatureStatus.IsReady) {
            setFocus('email_address');
        }
    }, [status, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (status === FeatureStatus.HasSubmitted) {
            navigate(ClientRoutes.home);
        }
    }, [status, navigate]);

    const location = useLocation();
    const state = location.state as CustomizedState;

    /* Set defaults, if they exist. */
    useEffect(() => {
        if (state) {
            reset({
                email_address: state?.emailAddress
            }, {keepDefaultValues: true});
        }
    }, [state, reset]);

    const onSubmitHandler = async (data: any) => {
        signInUser(data);
    };

    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <Logo/>
                    <div className="card">
                        <div className="card-body">
                            <h1 className="card-title">Sign in.</h1>
                            <FormError status={status} message={message}/>
                            <FormSuccess status={status} message={message || (state && state?.message)}/>
                            <form onSubmit={handleSubmit(onSubmitHandler)}>
                                <fieldset id="me" disabled={status === FeatureStatus.IsSubmitting}>
                                    <div className="form-floating">
                                        <input {...register('email_address')}
                                               type="text"
                                               className="form-control"
                                               id="floatingEmail"
                                               placeholder="name@example.com"/>
                                        <label htmlFor="floatingEmail">Email address</label>
                                        <small className="text-danger">
                                            {errors?.email_address?.message?.toString()}
                                        </small>
                                    </div>
                                    <div className="form-floating">
                                        <input {...register('password')}
                                               type="password"
                                               className="form-control"
                                               id="floatingPassword"
                                               placeholder="Password"/>
                                        <label htmlFor="floatingPassword">Password</label>
                                        <small className="text-danger">
                                            {errors?.password?.message?.toString()}
                                        </small>
                                    </div>
                                    <div>
                                        <SubmitButton
                                            isFormReady={isFormReady}
                                            featureStatus={status}
                                            text="Sign in"
                                        />
                                    </div>
                                </fieldset>
                            </form>
                            <div className="row">
                                <div className="col-4">
                                    <Link to={ClientRoutes.userSignUp} className="float-start">
                                        Sign up
                                    </Link>
                                </div>
                                <div className="col-8">
                                    <Link to={ClientRoutes.userChangePasswordRequest} className="float-end">
                                        Forgot password
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}