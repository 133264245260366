import {yupResolver} from '@hookform/resolvers/yup';
import * as React from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ApiRoutes} from '../../apiRoutes';
import {CustomizedState} from '../../utils/customizedState';
import {getFieldToFocus} from "../../utils/pageUtils";
import {ClientRoutes} from '../clientRoutes';
import FormError from "../common/FormError";
import Logo from '../common/Logo';
import SubmitButton from "../common/SubmitButton";
import {FeatureStatus} from "../featureStatus";
import {PageTitles} from '../pageTitles';
import usePostData from "../usePostData";
import {userEmailValidationSchema} from "./userValidationSchema";

export default function UserPasswordChangeRequest() {

    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as CustomizedState;

    const {status, message, validationErrors, submitForm, formData} = usePostData();

    useEffect(() => {
        document.title = PageTitles.userForgotPassword;
    }, []);

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setFocus,
        formState: {errors, isDirty, isValid}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(userEmailValidationSchema),
        shouldFocusError: true
    });

    const isFormReady = isDirty && isValid;

    /* Set defaults, if they exist. */
    useEffect(() => {
        if (state) {
            reset({
                email_address: state?.emailAddress
            }, {keepDefaultValues: true});
        }
    }, [state, reset]);

    useEffect(() => {
        const defaultField = 'email_address';

        if (status === FeatureStatus.HasError) {
            validationErrors.forEach(function (value: any) {
                setError(value.field, {type: 'custom', message: value.errorMessage});
            });

            const fieldName = getFieldToFocus(validationErrors, defaultField);
            setFocus(fieldName);

        } else if (status === FeatureStatus.IsReady) {
            setFocus(defaultField);
        }
    }, [status, validationErrors, setError, setFocus]);

    useEffect(() => {
        if (status === FeatureStatus.HasSubmitted) {
            navigate(ClientRoutes.userChangePasswordStatus, {state: {'emailAddress': formData.email_address}});
        }
    }, [navigate, status, formData]);

    const onSubmitHandler = async (data: any) => {
        submitForm(ApiRoutes.userRequestPasswordChange, data);
    };

    return (
        <div className="container account mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <Logo/>
                    <div className="card">
                        <div className="card-body">
                            <h1 className="card-title">Forgot your password?</h1>
                            <FormError status={status} message={message}/>
                            <div className="row">
                                <div className="col">
                                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                                        <fieldset id="me" disabled={status === FeatureStatus.IsSubmitting}>
                                            <div className="form-floating">
                                                <input {...register('email_address')} autoFocus type="text"
                                                       className="form-control" id="floatingEmail"
                                                       placeholder="name@example.com"/>
                                                <label htmlFor="floatingEmail">Email address</label>
                                                <small className="text-danger">
                                                    {errors?.email_address?.message?.toString()}
                                                </small>
                                            </div>
                                            <div>
                                                <SubmitButton
                                                    isFormReady={isFormReady}
                                                    featureStatus={status}
                                                    text="Continue"
                                                    isBlock={true}
                                                />
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <Link to={ClientRoutes.userSignIn} className="float-start">Sign in</Link>
                                </div>
                                <div className="col-8">
                                    <Link to={ClientRoutes.userSignUp} className="float-end">Sign up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}