import * as React from 'react';

export default function ContentError({message}: any) {

    if (message) {
        return (
            <div className="form-error alert alert-danger">
                <div className="row">
                    <div className="col-auto">
                        <i className="bi bi-exclamation-circle"></i>
                    </div>
                    <div className="col ps-0">
                        {message}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <></>
    );
}