import * as React from "react";
import {useState} from "react";
import ContentError from "../../common/ContentError";
import FormError from "../../common/FormError";
import {FeatureStatus} from "../../featureStatus";
import useReportDownload from "../../reports/useReportDownload";
import useAnalysis from "../useAnalysis";
import AnalysisCharts from "./AnalysisCharts";

export default function AnalysisContent({company, reportList}: any) {

    const companyId = company.companyId;
    const companyName = company.companyName;
    const sectorKey = company.sectorKey;
    const defaultReportDate = reportList[0].periodEndDate;

    const [reportDate, setReportDate] = useState<string>(defaultReportDate);

    const {status, message, chartData, resetAnalysis} = useAnalysis(companyId, sectorKey, reportDate);

    const handleReportDateChange = (event: any) => {
        resetAnalysis();
        setReportDate(event.target.value);
    };

    const {
        status: downloadStatus,
        message: downloadMessage,
        downloadReport
    } = useReportDownload(companyId, companyName, reportDate);

    const handleDownload = () => {
        downloadReport();
    };

    return (
        <div className="content analysis">
            <div className="row mb-4">
                <div className="col">
                    <h2>Select your report</h2>
                </div>
                <div className="col-auto">
                    <select
                        id="reportDate"
                        className="form-select form-select-sm"
                        disabled={status === FeatureStatus.IsLoading}
                        onChange={handleReportDateChange}
                    >
                        {reportList.map((e: any) =>
                            <option
                                value={e.periodEndDate}
                                key={e.periodEndDate}>
                                {e.periodEndDate} (Q{e.quarter})
                            </option>)}
                    </select>
                </div>
                <div className="col-auto">
                    <button
                        className="btn btn-primary btn-sm"
                        disabled={status === FeatureStatus.IsLoading}
                        onClick={handleDownload}
                    >
                        Download
                    </button>
                </div>
            </div>

            <ContentError message={message}/>
            <FormError status={downloadStatus} message={downloadMessage}/>

            <div className="row">
                <div className="col">
                    <AnalysisCharts
                        key={reportDate}
                        status={status}
                        chartData={chartData}
                    />
                </div>
            </div>
        </div>
    );
}