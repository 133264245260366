export default class Colors {

    static readonly primaryGray = 'rgb(149, 170, 201)';
    static readonly primaryMuted = '#d2ddec'; // Gauge muted.

    static readonly primaryDark = '#1f56a0';
    static readonly primary = '#2c7be5'; // Company rgb(44, 123, 229).
    static readonly primaryLight = '#8BC1F7'; // 6ba3ed

    static readonly secondary = '#7CC674'; // Industry
    static readonly lightLine = '#f5f5f5'; // Dashed lines. Grid.
    static readonly lineMedium = 'rgb(110, 132, 163)'; // Reference line.

    static readonly textMedium = 'rgb(110, 132, 163)'; // Chart text. Menu text. #6E84A3

    static readonly headerDefault = '#212529'; // rgb(33, 37, 41).
}
