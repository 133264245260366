import * as React from 'react';

export default function PricingPlan({plan}: any) {

    function GetCheck() {
        return (
            <i className="bi bi-check-circle text-success"></i>
        );
    }

    function GetX() {
        return (
            <i className="bi bi-x-lg text-danger"></i>
        );
    }

    const GetBool = (props: any) => {
        const isIncluded = props.isIncluded;

        if (isIncluded) {
            return (<GetCheck/>);
        }

        return (<GetX/>);
    };

    function GetPrice({plan}: any) {
        if (isNaN(plan.price)) {
            return (
                <h3 className="card-title contact">{plan.price}</h3>
            );
        }

        return (
            <>
                <h3 className="card-title"><span>$</span>{plan.price}</h3>
                <p className="card-text">/ month</p>
            </>
        );
    }

    return (
        <div className="card text-center">
            <div className="card-header">
                <h2>{plan.planName} Plan</h2>
            </div>
            <div className="card-body">
                <GetPrice plan={plan}/>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <div className="row">
                        <div className="col">Companies</div>
                        <div className="col-auto">{plan.companyCount}</div>
                    </div>
                </li>

                <li className="list-group-item">
                    <div className="row">
                        <div className="col">Reports</div>
                        <div className="col-auto">{plan.reportCount}</div>
                    </div>
                </li>

                <li className="list-group-item">
                    <div className="row">
                        <div className="col">Import reports</div>
                        <div className="col-auto">
                            <GetBool isIncluded={plan.importReports}/>
                        </div>
                    </div>
                </li>

                <li className="list-group-item">
                    <div className="row">
                        <div className="col">Export reports (CSV, Excel, PDF)</div>
                        <div className="col-auto">
                            <GetBool isIncluded={plan.exportReports}/>
                        </div>
                    </div>
                </li>

                <li className="list-group-item">
                    <div className="row">
                        <div className="col">Custom reports</div>
                        <div className="col-auto">
                            Contact us
                        </div>
                    </div>
                </li>
            </ul>
            <div className="card-footer">
                <a href="#" className="btn btn-primary w-100">
                    Start with {plan.planName}
                </a>
            </div>
        </div>
    );
}