import * as React from 'react';
import {Link} from 'react-router-dom';
import {ClientRoutes} from "../clientRoutes";

import {ReactComponent as GenericCompany} from './generic-company.svg';

export default function NoCompaniesExist() {
    return (
        <div className="no-companies">
            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center generic-company">
                        <GenericCompany height={150}/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <h1 className="header-title text-center">No companies yet.</h1>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center text-body-tertiary mb-3">Create a company to start adding reports.</div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col">
                    <div className="text-center">
                        <Link to={ClientRoutes.companyNew} className="btn btn-primary btn-lg">
                            Add company
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}