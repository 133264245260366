import * as React from 'react';
import {Link} from "react-router-dom";
import {ClientRoutes} from "../clientRoutes";
import {FeatureStatus} from "../featureStatus";

export default function FormError({status, message}: any) {

    let template;

    if (message && status === FeatureStatus.HasError) {
        template = (
            <div className="form-error alert alert-danger">
                <div className="row">
                    <div className="col-auto">
                        <i className="bi bi-exclamation-circle"></i>
                    </div>
                    <div className="col ps-0">
                        {message}
                    </div>
                </div>
            </div>
        );
    } else if (message && status === FeatureStatus.UserEmailUnverified) {
        template = (
            <div className="form-error alert alert-danger">
                <div className="row">
                    <div className="col-auto">
                        <i className="bi bi-exclamation-circle"></i>
                    </div>
                    <div className="col ps-0">
                        {message}
                        <Link to={ClientRoutes.userEmailVerificationRequest}>
                            Resend email
                        </Link>
                    </div>
                </div>
            </div>
        );
    } else {
        template = (<></>);
    }

    return (
        <>{template}</>
    );
}