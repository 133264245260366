import * as React from 'react';
import {Link} from "react-router-dom";
import {ClientRoutes} from "../../clientRoutes";

export default function CompanyHeader({company}: any) {

    const preTitle = 'Company Info';
    let title = 'New company';
    let sectorName = null;
    let hasCompany = false;

    if (company) {
        hasCompany = true;
        title = company.companyName;
        sectorName = company.sectorName;
    }

    return (
        <div className="analysis header">
            <div className="row align-items-end d-flex align-items-center">
                <div className="col">
                    <h6 className="header-pretitle">
                        {preTitle}
                    </h6>
                    <h1 className="header-title">
                        {title}
                        {hasCompany && (
                            <span className="badge bg-primary-subtle">{sectorName}</span>
                        )}
                    </h1>
                </div>
                <div className="col-auto">
                    {
                        hasCompany && (
                            <Link to={ClientRoutes.reportsAdd} className="btn btn-primary btn-lg">
                                Add report
                            </Link>
                        )
                    }
                </div>
            </div>
        </div>
    );
}