import {Kpi} from "./kpi";

// Reorder as per Ricardo.
const KPI_ORDER = [
    'current_ratio',
    'quick_ratio',
    'cash_ratio',

    'dso',
    'adi',
    'adp',
    'cash_cycle',

    'ar_turnover',
    'inventory_turnover',
    'ap_turnover',
    'wc_turnover',
    'total_asset_turnover',
    'fixed_asset_turnover',
    'tangible_asset_turnover',
    'ppe_turnover',

    'roa',
    'roe',

    'net_margin',
    'ebit',
    'basic_earning_power',
    'roce',

    'debt_ratio',
    'long_term_debt_ratio',
    'de_ratio',

    'ncg',
    'ocg',
    'clcc',
    'ocs',
    'qpt',
    'qoffur',

    'lyca',
    'iaicoc',
    'roa2bond'
];

// TODO: Move to analysis subfolder.
export function sortKpis(kpis: Kpi[]) {

    const sortedKpis: Kpi[] = [];

    KPI_ORDER.forEach(function (kpiKey: string) {
        kpis.forEach(function (kpi: Kpi) {
            if (kpi.kpiKey === kpiKey) {
                sortedKpis.push(kpi);
                return;
            }
        });
    });

    return sortedKpis;
}