import * as React from "react";
import {FeatureStatus} from "../../featureStatus";
import NoCompaniesExist from "../../common/NoCompaniesExist";
import PageError from "../../common/PageError";
import Status from "../../common/Status";
import useCompanyReport from "../../useCompanyReport";
import {ReportAddForm} from "./ReportAddForm";
import ReportHeader from "./ReportHeader";

export function ReportAdd({companyId}: any) {

    const {status, message, company} = useCompanyReport(companyId);

    let template;

    if (status === FeatureStatus.IsLoading) {
        template = (<Status message="Loading reports"/>);
    } else if (status === FeatureStatus.HasError) {
        template = (<PageError message={message}/>);
    } else if (status === FeatureStatus.IsMissingCompany) {
        template = (<NoCompaniesExist/>);
    } else {
        template = (<ReportAddForm company={company} />);
    }

    return (
        <>
            <ReportHeader pretitle="Add Report" company={company} isNew={true}/>
            {template}
        </>
    );
}