export enum PostStatus {
    IsCanceled = 'is_canceled',

    // The following is set from the API. Do not change.
    UserEmailUnverified = 'user_email_unverified',

    RequiresAuth = 'requires_auth',

    HasError = 'has_error',

    // The following is set from the API. Do not change.
    Ok = 'ok'
}