export class ApiRoutes {
    static readonly apiUrl = process.env.REACT_APP_API_URL;

    static readonly countriesList = `${ApiRoutes.apiUrl}/countries/list`;
    static readonly currenciesList = `${ApiRoutes.apiUrl}/currencies/list`;

    static readonly companiesCreate = `${ApiRoutes.apiUrl}/companies/create`;
    static readonly companiesList = `${ApiRoutes.apiUrl}/companies/list`;
    static readonly companiesUpdate = `${ApiRoutes.apiUrl}/companies/update`;

    static readonly companyReportsCreate = `${ApiRoutes.apiUrl}/companies/reports/create`;
    static readonly companyReportsList = `${ApiRoutes.apiUrl}/companies/reports/list`;
    static readonly companyReportsUpload = `${ApiRoutes.apiUrl}/companies/reports/upload`;

    static readonly companyMetricsGetBenchmarks = `${ApiRoutes.apiUrl}/companies/metrics/get-benchmarks`;
    static readonly companyMetricsGetKpis = `${ApiRoutes.apiUrl}/companies/metrics/get-kpis`;
    static readonly companyMetricsGetPrognoses = `${ApiRoutes.apiUrl}/companies/metrics/get-kpi-prognoses`;
    static readonly companyMetricsGetPrognosesCcp = `${ApiRoutes.apiUrl}/companies/metrics/get-ccp-prognoses`;
    static readonly companyMetricsDownload = `${ApiRoutes.apiUrl}/companies/metrics/download`;

    static readonly industriesList = `${ApiRoutes.apiUrl}/industries/list`;
    static readonly industriesMetricsGetBenchmarks = `${ApiRoutes.apiUrl}/industries/metrics/get-benchmarks`;
    static readonly industriesMetricsGetKpiAggs = `${ApiRoutes.apiUrl}/industries/metrics/get-kpi-aggregates`;
    static readonly industriesMetricsGetPrognoses = `${ApiRoutes.apiUrl}/industries/metrics/get-kpi-prognoses`;

    static readonly userSignIn = `${ApiRoutes.apiUrl}/user/access-token`;
    static readonly userChangePassword = `${ApiRoutes.apiUrl}/user/change-password`;
    static readonly userGet = `${ApiRoutes.apiUrl}/user/get`;
    static readonly userRequestEmailVerification = `${ApiRoutes.apiUrl}/user/request-email-verification`;
    static readonly userRequestPasswordChange = `${ApiRoutes.apiUrl}/user/request-password-change`;
    static readonly userSignUp = `${ApiRoutes.apiUrl}/user/sign-up`;
    static readonly userVerifyEmail = `${ApiRoutes.apiUrl}/user/verify-email`;
}