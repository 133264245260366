export class Industry {
    sectorKey: string = '';
    sectorName: string = '';
    subsectorKey: string = '';
    subsectorName: string = '';

    fromJson(json: any) {
        this.sectorKey = json.sector_key;
        this.sectorName = json.sector_name;
        this.subsectorKey = json.subsector_key;
        this.subsectorName = json.subsector_name;
    }
}