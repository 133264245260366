import * as React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export function UploadReportModal(props: any) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload file
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <label htmlFor="uploadFileInput" className="form-label">Select input file</label>
                    <input
                        className="form-control"
                        id="uploadFileInput"
                        onChange={props.onFileChange}
                        type="file"/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button id="uploadButton" disabled variant="primary" onClick={props.onSubmit}>Upload</Button>
                <Button variant="outline-secondary" onClick={props.onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}