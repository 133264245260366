export class ReportEntry {
    accountKey: string = '';
    accountName: string = '';
    accountCategory: string = '';
    amount: number = 0;

    fromJson(json: any) {
        this.accountKey = json.account_key;
        this.accountName = json.account_name;
        this.accountCategory = json.account_category;
        this.amount = json.amount;
    }
}