import {ReportStatement} from "./reportStatement";

export class Report {
    periodEndDate: string = '';
    quarter: string = '';
    statements: ReportStatement[] = [];

    fromJson(json: any) {
        this.periodEndDate = json.period_end_date;
        this.quarter = json.quarter;

        const stmts = json.statements;
        const statementList: ReportStatement[] = [];

        stmts.forEach(function (stmt: any) {
            const reportStatement = new ReportStatement();
            reportStatement.fromJson(stmt);
            statementList.push(reportStatement);
        });

        this.statements = statementList;
    }

    getStatement(statementKey: string) {
        let stmt = null;

        this.statements.forEach(function (s: ReportStatement) {
            if (s.statementKey === statementKey) {
                stmt = s;
                return;
            }
        });

        return stmt;
    }

    getBalanceSheetStatement() {
        return this.getStatement('balance_sheet');
    }

    getCashflowStatement() {
        return this.getStatement('cashflow_statement');
    }

    getIncomeStatement() {
        return this.getStatement('income_statement');
    }
}