export function round(val: number, digits: number) {
    if (val === null) {
        console.warn('Value is null.');
        return 0;
    }

    if (val === undefined) {
        console.warn('Value is undefined.');
        return 0;
    }

    return parseFloat(val.toFixed(digits));
}