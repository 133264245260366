import {useEffect, useState} from "react";
import {ApiRoutes} from "../../apiRoutes";
import {useAuth} from "../../AuthProvider";
import {postData} from "../../utils/postData";
import {getHeaders} from "../../utils/requestHeaders";
import {FeatureStatus} from "../featureStatus";
import {KpiList} from "./kpiList";
import {Report} from "./report";

export default function useReportList(
    companyId: string,
    sectorKey: string,
    reportList: Report[],
    reportDate: string) {

    const {getToken} = useAuth();

    const [status, setStatus] = useState(FeatureStatus.IsLoading);
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    // Fetched data in correct shape.
    const [report, setReport] = useState<Report | null>(null);
    const [kpiList, setKpiList] = useState<KpiList | null>(null);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const postForm = async () => {
            if (status !== FeatureStatus.IsLoading) {
                return;
            }

            //
            // Get current report.
            // If we get here, we assume there is at least one report.
            //
            let selectedReport: Report | null = null;

            reportList.forEach(function (report: Report) {
                if (report.periodEndDate === reportDate) {
                    selectedReport = report;
                    return;
                }
            });

            function doExitEarly(results: any) {
                if (results.isCanceled) {
                    return true;
                }

                if (results.requiresAuth) {
                    setStatus(FeatureStatus.RequiresAuth);
                    return true;
                }

                const message = results.message;
                const errors = results.errors;

                if (results.hasError) {
                    setStatus(FeatureStatus.HasError);
                    setMessage(message);
                    setValidationErrors(errors);

                    // Reset all results. Don't display partial results.
                    setReport(null);
                    setKpiList(null);

                    return true;
                }

                return false;
            }

            const token = getToken();
            const headers = getHeaders(token);

            //
            // Get KPIs.
            //

            const data = {
                "company_id": companyId,
                "period_end_date": reportDate,
                "kpi_transformations": ["none"]
            };

            const kpiResults = await postData(ApiRoutes.companyMetricsGetKpis, data, headers, signal);

            if (doExitEarly(kpiResults)) {
                return;
            }

            const kpiList = new KpiList();
            kpiList.fromJson(kpiResults.results);

            setReport(selectedReport);
            setKpiList(kpiList);

            setStatus(FeatureStatus.IsLoaded);
        };

        postForm().then();

        return () => {
            controller.abort();
        };

    }, [status, companyId, sectorKey, reportList, reportDate, getToken]);

    const resetReport = () => {
        setStatus(FeatureStatus.IsLoading);
        setMessage('');
        setValidationErrors([]);
        setReport(null);
        setKpiList(null);
    };

    return {
        status, message, validationErrors, report, kpiList, resetReport
    };
}