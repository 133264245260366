import * as React from 'react';
import {useEffect} from 'react';
import Logo from "../common/Logo";
import {PageTitles} from '../pageTitles';
import {Plan} from "./plan";
import PricingPlan from "./PricingPlan";

export default function Pricing() {

    useEffect(() => {
        document.title = PageTitles.publicPricing;
    }, []);

    const basicPlan: Plan = {
        planName: "Basic",
        price: "49",
        reportCount: "1 report",
        companyCount: "1 company",
        importReports: false,
        exportReports: false
    };

    const standardPlan: Plan = {
        planName: "Standard",
        price: "99",
        reportCount: "10 reports",
        companyCount: "10 companies",
        importReports: false,
        exportReports: true
    };

    const premiumPlan: Plan = {
        planName: "Premium",
        price: "Contact us",
        reportCount: "Unlimited",
        companyCount: "Unlimited",
        importReports: true,
        exportReports: true
    };

    return (
        <div className="container public pricing mt-4 mt-md-5">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <Logo/>
                    <h1>Plans &amp; Pricing</h1>
                    <p>We have plans and prices that fit your business perfectly.</p>
                    <div className="row">
                        <div className="col">
                            <PricingPlan plan={basicPlan}/>
                        </div>
                        <div className="col">
                            <PricingPlan plan={standardPlan}/>
                        </div>
                        <div className="col">
                            <PricingPlan plan={premiumPlan}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}